import $ from 'jquery';
import { clearErrorAndWarn, setError } from './utils';
import {
  validateDate,
  getWeekday,
  dateExcludingWeekends,
  yesterdayExcludingWeekends,
  DAY_CLASSES
} from './date_utils';
import openBookingHoursModal from './modal_util';
import { getWorkingHoursForDate, defaultHoursFor } from './working_hour_utils';
import { populateTimeRow, selectDefaultHours } from './booking_tab_utils';
import { ajaxCall } from 'ajax_call';

const TimeForm = () => {
  $(document).on('click', '.booking-form .booked-hours .btn.lots', event => {
    clearAndTidy();
    $('.time-date').show();

    var $bookedTimeForm = $('#new_booked_time')[0];
    var href = '/booked_times';
    $bookedTimeForm.action = href;
    $bookedTimeForm.method = 'post';

    timeFormContents();
  });

  $(document).on(
    'click',
    '.booking-form .booked-hours .btn.yesterday',
    event => {
      const yesterday = yesterdayExcludingWeekends();
      const dateString = $('.yesterday').prop('title');
      const date = new Date(Date.parse(dateString));
      populateTimeRow(date, 'yesterday');
    }
  );

  $(document).on('click', '.booking-form .booked-hours .btn.today', event => {
    populateTimeRow(new Date(), 'today');
  });

  $(document).on('change', '.booked-hours input', event => {
    if ($(event.target)[0].action) {
      var data = {};

      if ($(event.target).hasClass('hours')) {
        data = {
          booked_time: { hours: $(event.target).val() }
        };
      } else {
        data = {
          booked_time: { description: $(event.target).val() }
        };
      }
      ajaxCall('put', $(event.target)[0].action, data);
    }
  });

  $(document).on('change', '.time-form .time-date select', event => {
    clearAndTidy();
    $('.new-booked-time.lots').remove();
    var updateFinish = false;

    if (
      $(event.target)
        .closest('.time-date')
        .hasClass('start')
    ) {
      updateFinish = true;
    }

    const startDate = validateDates(updateFinish);
    if (!startDate) {
      return;
    }

    const finishDate = validateDate('booked_time_finish');
    if (!finishDate) {
      return;
    }

    var date = startDate;
    var newHours = 0.0;
    var combinedHours = 0.0;

    var daysHours = [];
    daysHours[0] = 0.0;
    daysHours[1] = 0.0;
    daysHours[2] = 0.0;
    daysHours[3] = 0.0;
    daysHours[4] = 0.0;
    daysHours[5] = 0.0;
    daysHours[6] = 0.0;

    for (date; date <= finishDate; date.setDate(date.getDate() + 1)) {
      newHours = selectDefaultHours(date);
      combinedHours += newHours;

      var dayOfWeek = date.getDay();
      daysHours[dayOfWeek] += newHours;
      $(DAY_CLASSES[dayOfWeek]).find('.day-combined')[0].innerText =
        daysHours[dayOfWeek];

      populateTimeRow(date, 'lots');
    }

    $('.total')[0].innerText = combinedHours;
  });

  $(document).on('click', '.time-form .new_booked_time .btn', event => {
    const url = event.delegateTarget.URL;
    if (
      !url.endsWith('type=listWeek') &&
      !url.endsWith('active_tab=calendar')
    ) {
      const bookingForm = $('.booking-form');
      bookingForm.show();
      bookingForm.prev().show();
    }
  });
};

const clearAndTidy = () => {
  // Clear old errors and state first
  clearErrorAndWarn();
  $('.day-combined.strong').removeClass('strong');
  $('.day-combined').empty();
  $('.total')[0].innerText = 0.0;
  $('.warn-hours').hide();
  $('textarea#booked_time_description').empty();
};

const validateDates = updateFinish => {
  $('.booked_time_finish').removeClass('required');

  var startDate = validateDate('booked_time_date');
  if (startDate) {
    $('.time-date.start .cwday')[0].innerText = getWeekday(startDate);
  }

  var finishDate = validateDate('booked_time_finish');
  if (finishDate) {
    $('.time-date.finish .cwday')[0].innerText = getWeekday(finishDate);
  }

  if (finishDate && startDate) {
    if (finishDate < startDate) {
      if (updateFinish) {
        $('#booked_time_finish_1i').val(startDate.getFullYear());
        $('#booked_time_finish_2i').val(startDate.getMonth() + 1);
        $('#booked_time_finish_3i').val(startDate.getDate());

        $('.time-date.finish .cwday')[0].innerText = getWeekday(startDate);

        return startDate;
      } else {
        $('.booked_time_finish').addClass('required');
        setError('errors.booking.negative', $('.booking-alert'));
        return false;
      }
    }
    return startDate;
  }

  return false;
};

const timeFormContents = () => {
  const booking_id = $('#booking_id').val();

  // Default to today
  var dateClicked = new Date();
  const status = $('#booking_status')[0].value;

  if ($('.table-body .row').length > 0) {
    const $lastRow = $('.table-body .row').last();
    var lastDateText = $lastRow.find('.date')[0].innerText;
    const $firstRow = $('.table-body .row').first();
    var firstDateText = $firstRow.find('.date')[0].innerText;

    var lastDate = new Date(lastDateText);
    var firstDate = new Date(firstDateText);

    dateClicked = lastDate;

    // Use whichever is the later date to work out what we should suggest as the default
    if (firstDate > lastDate) {
      dateClicked = firstDate;
    }

    dateClicked.setDate(dateClicked.getDate() + 1);
    dateClicked = dateExcludingWeekends(dateClicked);
  }

  //Hide the booking form while adding booked time
  const bookingForm = $('.booking-form');
  bookingForm.hide();
  bookingForm.prev().hide();

  openBookingHoursModal(booking_id, dateClicked);

  validateDates();
};

export default TimeForm;
